import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from "vue";
import DialogModule from "@/store/UI/Dialog";
import { IActivityLogDTO } from "@/models/ActivityLog";
import LogReadField from "@/components/Common/PayloadHelper/LogReadonlyField.vue";
import { SYSTEM } from "@ems/locales/system";
import { formatDateTime } from "@/utils/helpers/convertDateTime";


export default /*@__PURE__*/_defineComponent({
  __name: 'JsonLog',
  setup(__props, { expose: __expose }) {
  __expose();

const dataLog = computed(() => DialogModule.getData as IActivityLogDTO);
const formattedPayload = computed(() => {
  try {
    return JSON.stringify(JSON.parse(dataLog.value.Payload), null, 2);
  } catch (error) {
    return "Invalid JSON data";
  }
});

const handleClose = () => {
  DialogModule.hideModal();
};

const __returned__ = { dataLog, formattedPayload, handleClose, computed, PropType, get DialogModule() { return DialogModule }, get IActivityLogDTO() { return IActivityLogDTO }, LogReadField, get SYSTEM() { return SYSTEM }, get formatDateTime() { return formatDateTime } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})