import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white px-4 py-2 rounded-md shadow-md" }
const _hoisted_2 = { class: "flex flex-col text-sm" }
const _hoisted_3 = { class: "flex flex-col px-2 pt-1 gap-2" }
const _hoisted_4 = { class: "font-bold text-neutral-dark text-sm" }
const _hoisted_5 = { class: "text-neutral-black overflow-scroll max-h-40" }
const _hoisted_6 = { class: "whitespace-pre-wrap text-sm break-word-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.DialogModule.isToggle,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.DialogModule.isToggle) = $event)),
    center: "",
    "before-close": $setup.handleClose,
    "show-close": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "text-lg font-bold text-neutral-dark mb-3" }, "Information", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["LogReadField"], {
            label: $setup.SYSTEM.action_type,
            content: $setup.dataLog.ActivityDescription
          }, null, 8, ["label", "content"]),
          _createVNode($setup["LogReadField"], {
            label: $setup.SYSTEM.user_name,
            content: $setup.dataLog.UserName
          }, null, 8, ["label", "content"]),
          _createVNode($setup["LogReadField"], {
            label: $setup.SYSTEM.related_feature,
            content: $setup.dataLog.Category
          }, null, 8, ["label", "content"]),
          _createVNode($setup["LogReadField"], {
            label: $setup.SYSTEM.created_time,
            content: $setup.formatDateTime($setup.dataLog.CreatedDate)
          }, null, 8, ["label", "content"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString($setup.SYSTEM.payload) + ": ", 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("pre", _hoisted_6, _toDisplayString($setup.formattedPayload), 1)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}