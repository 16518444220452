import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center text-sm px-2 py-1 gap-10" }
const _hoisted_2 = { class: "w-1/3 font-bold text-neutral-dark" }
const _hoisted_3 = { class: "w-2/3 text-neutral-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($setup.props.label) + ":", 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString($setup.props.content?.length! > 0  ? $setup.props.content : $setup.SYSTEM.na), 1)
  ]))
}