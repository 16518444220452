import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-10 py-4 pb-8" }
const _hoisted_2 = { class: "flex items-center py-2" }
const _hoisted_3 = { class: "shadow-lg rounded-lg relative bg-white mt-2" }
const _hoisted_4 = { class: "flex items-center justify-center space-x-2" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "text-center w-full" }

import { computed, onMounted, ref, watch } from "vue";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import { formatDateTime } from "@/utils/helpers/convertDateTime";
import AccountModule from "@/store/modules/Account";
import { IActivityLogDTO, IActivityLogParameter } from "@/models/ActivityLog";
import {
  ACTION_TYPE_FILTER,
  RELATED_FEATURE_FILTER,
} from "@ems/constants/filter";
import DialogModule from "@/store/UI/Dialog";
import LogBadge from "@/components/Common/Status/LogBadge.vue";
import JsonLog from "@/components/Common/PayloadHelper/JsonLog.vue";
import { STATUS_LOG } from "@ems/constants/account_registration_status";
import SelectFilter from "@/components/Common/FilterHelper/SelectFilter.vue";
import SearchInput from "@/components/Common/SearchHelper/SearchInput.vue";
import { SEARCH_TEXT } from "@ems/locales/search";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const disabled = ref(false);
const small = ref(true);
const currentPage = ref(1);
const pageSize = ref(10);
const searchText = ref("");
const sortBy = ref("");
const sortOrder = ref(1);
const isSearch = ref(false);
const saveInputSearch = ref("");

const actionTypeOptions = ref<string[]>([]);
const relatedFeatureOptions = ref<string[]>([]);
const visibleFilter = ref({
  actionType: false,
  relatedFeature: false,
});

const activityLogList = computed(() => AccountModule.dataActivityLogsGetter);
const paginationGet = computed(
  () => AccountModule.paginationActivityLosgGetter
);
const isLoadingActivityLogs = AccountModule.isLoadingActivityLogsGetter;

const handleFilterActionType = (selectedOptions: string[]) => {
  actionTypeOptions.value = selectedOptions;
  currentPage.value = 1;
  getActivityLog({
    Search: isSearch.value ? saveInputSearch.value : "",
    ActivityNames: actionTypeOptions.value,
    Categories: relatedFeatureOptions.value,
  });
};

const handleFilterRelatedFeature = (selectedOptions: string[]) => {
  relatedFeatureOptions.value = selectedOptions;
  currentPage.value = 1;
  getActivityLog({
    Search: isSearch.value ? saveInputSearch.value : "",
    ActivityNames: actionTypeOptions.value,
    Categories: relatedFeatureOptions.value,
  });
};

const handleOpenModal = (payload: any) => {
  DialogModule.showModal({
    component: JsonLog,
    data: payload,
  });
};

const handleSizeChange = (val: number) => {
  currentPage.value = 1;
  pageSize.value = val;
  getActivityLog({
    Search: isSearch.value ? saveInputSearch.value : "",
    ActivityNames: actionTypeOptions.value,
    Categories: relatedFeatureOptions.value,
  });
};

const handleCurrentChange = (val: number) => {
  currentPage.value = val;
  getActivityLog({
    Search: isSearch.value ? saveInputSearch.value : "",
    ActivityNames: actionTypeOptions.value,
    Categories: relatedFeatureOptions.value,
  });
};

const handleSortChange = (event: any) => {
  sortBy.value = event.prop === "UserName" ? "user_name" : "created_date";
  sortOrder.value = event.order === "ascending" ? 0 : 1;
  getActivityLog({
    Search: isSearch.value ? saveInputSearch.value : "",
    ActivityNames: actionTypeOptions.value,
    Categories: relatedFeatureOptions.value,
    OrderBy: sortBy.value,
  });
};

const handleSearchUserName = () => {
  isSearch.value = true;
  saveInputSearch.value = searchText.value.trim();
  currentPage.value = 1;
  if (saveInputSearch.value === "") {
    return;
  }
  getActivityLog({
    Search: saveInputSearch.value,
    ActivityNames: actionTypeOptions.value,
    Categories: relatedFeatureOptions.value,
  });
};

const getActivityLog = (params?: IActivityLogParameter) => {
  AccountModule.getActivityLogsAction({
    ...params,
    CurrentPage: currentPage.value,
    Limit: pageSize.value,
    OrderBy:
      sortBy.value && sortBy.value !== ""
        ? sortBy.value === "UserName"
          ? "user_name"
          : sortBy.value
        : "created_date",

    OrderByDirection: sortOrder.value ?? 1,
  });
};

watch(searchText, (newVal) => {
  if (newVal.trim() === "") {
    isSearch.value = false;
    saveInputSearch.value = "";
    getActivityLog({
      ActivityNames: actionTypeOptions.value,
      Categories: relatedFeatureOptions.value,
    });
  }
});

onMounted(() => {
  getActivityLog();
});

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(SearchInput, {
        class: "w-full",
        modelValue: searchText.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
        onSearch: handleSearchUserName,
        onClear: handleSearchUserName,
        placeholder: _unref(SEARCH_TEXT).search_by_user_name
      }, null, 8, ["modelValue", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        class: "w-full table-custom px-4 overflow-y-auto",
        style: {
          borderRadius: '4px',
          borderColor: 'transparent',
          color: '#000000',
        },
        onSortChange: handleSortChange,
        data: activityLogList.value,
        "max-height": "66vh",
        stripe: "",
        "cell-class-name": "text-cafe-dark",
        "header-row-class-name": "tableHeaderRow",
        "row-class-name": "tableRow",
        "element-loading-text": "Loading...",
        "element-loading-background": "rgba(0, 0, 0, 0.6)"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "Action",
            label: "Payload",
            "header-align": "center",
            align: "center"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  type: "button",
                  onClick: _withModifiers(($event: any) => (handleOpenModal(scope.row)), ["prevent"])
                }, _cache[9] || (_cache[9] = [
                  _createElementVNode("p", { class: "text-green-secondary font-bold" }, "View", -1)
                ]), 8, _hoisted_5)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "ActivityDescription",
            width: "400"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["flex items-center gap-2 h-5", {
                'text-green-secondary': actionTypeOptions.value.length > 0,
                '': actionTypeOptions.value.length === 0,
              }])
              }, [
                _createElementVNode("span", {
                  class: "cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => {
                  visibleFilter.value.actionType = !visibleFilter.value.actionType;
                  visibleFilter.value.relatedFeature = false;
                }, ["prevent"]))
                }, "Action Type"),
                _createVNode(BaseSvgIcon, {
                  class: "w-4 cursor-pointer",
                  name: "filter",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => {
                  visibleFilter.value.actionType = !visibleFilter.value.actionType;
                  visibleFilter.value.relatedFeature = false;
                }, ["prevent"]))
                })
              ], 2),
              _createVNode(SelectFilter, {
                actions: _unref(ACTION_TYPE_FILTER),
                visible: visibleFilter.value.actionType,
                "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((visibleFilter.value.actionType) = $event)),
                onApply: handleFilterActionType
              }, {
                reference: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createElementVNode("div", null, null, -1)
                ])),
                _: 1
              }, 8, ["actions", "visible"])
            ]),
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.ActivityDescription), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "UserName",
            label: "User Name",
            sortable: "",
            width: "150"
          }),
          _createVNode(_component_el_table_column, {
            prop: "ActionStatus",
            label: "Status",
            "header-align": "center",
            align: "center"
          }, {
            default: _withCtx((scope) => [
              _createVNode(LogBadge, {
                status: 
                scope.row.ActionStatus === _unref(STATUS_LOG)[_unref(STATUS_LOG).Success]
                  ? _unref(STATUS_LOG).Success
                  : _unref(STATUS_LOG).Fail
              
              }, null, 8, ["status"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, { prop: "RelatedFeature" }, {
            header: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["flex items-center gap-2 h-5 cursor-pointer", {
                'text-green-secondary': relatedFeatureOptions.value.length > 0,
                '': relatedFeatureOptions.value.length === 0,
              }])
              }, [
                _createElementVNode("span", {
                  onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => {
                  visibleFilter.value.relatedFeature = !visibleFilter.value.relatedFeature;
                  visibleFilter.value.actionType = false;
                }, ["prevent"]))
                }, "Related Feature"),
                _createVNode(BaseSvgIcon, {
                  class: "w-4",
                  name: "filter",
                  onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => {
                  visibleFilter.value.relatedFeature = !visibleFilter.value.relatedFeature;
                  visibleFilter.value.actionType = false;
                }, ["prevent"]))
                })
              ], 2),
              _createVNode(SelectFilter, {
                actions: _unref(RELATED_FEATURE_FILTER),
                visible: visibleFilter.value.relatedFeature,
                "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((visibleFilter.value.relatedFeature) = $event)),
                onApply: handleFilterRelatedFeature
              }, {
                reference: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createElementVNode("div", null, null, -1)
                ])),
                _: 1
              }, 8, ["actions", "visible"])
            ]),
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(RELATED_FEATURE_FILTER)[0].text), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "CreatedDate",
            sortable: "",
            label: "Created Date"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("p", null, _toDisplayString(_unref(formatDateTime)(scope.row.CreatedDate)), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _unref(isLoadingActivityLogs)]
      ]),
      (activityLogList.value.length > 0)
        ? (_openBlock(), _createBlock(_component_el_pagination, {
            key: 0,
            currentPage: currentPage.value,
            "onUpdate:currentPage": _cache[7] || (_cache[7] = ($event: any) => ((currentPage).value = $event)),
            "page-size": pageSize.value,
            "onUpdate:pageSize": _cache[8] || (_cache[8] = ($event: any) => ((pageSize).value = $event)),
            "page-sizes": [10, 20, 30, 40],
            size: "small",
            disabled: disabled.value,
            background: false,
            layout: "sizes, slot, prev, pager, next",
            total: paginationGet.value.TotalRow,
            onSizeChange: handleSizeChange,
            onCurrentChange: handleCurrentChange,
            class: "px-4 py-3 text-neutral-dark font-semibold"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", null, " Show " + _toDisplayString((currentPage.value - 1) * pageSize.value + 1) + " to " + _toDisplayString(Math.min(currentPage.value * pageSize.value, paginationGet.value.TotalRow)) + " of " + _toDisplayString(paginationGet.value.TotalRow) + " Items ", 1)
              ])
            ]),
            _: 1
          }, 8, ["currentPage", "page-size", "disabled", "total"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})